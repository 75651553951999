import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { ProfessionalInfoT } from '../components/ProfessionalAddForm/types';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';

export const useProfessionalForm = ({
  emailError,
  requiredError,
  lengthMedicalNumberError,
}: {
  emailError: string;
  requiredError: string;
  lengthMedicalNumberError: string;
}) => {
  const formDefaultValues = {
    firstName: '',
    lastName: '',
    email: '',
    medicalNumber: '',
    access: { name: '' },
    phone: '',
  };

  const profileSchema = yup
    .object({
      firstName: yup.string().required(requiredError!),
      lastName: yup.string().required(requiredError!),
      email: yup.string().email(emailError!).required(requiredError!),
      medicalNumber: yup
        .string()
        .required(requiredError!)
        .min(2, lengthMedicalNumberError),
      phone: yup.string(),
    })
    .required();

  const {
    register,
    watch,
    getValues,
    setValue,
    handleSubmit,
    clearErrors,
    setError,
    trigger,
    reset,

    formState: { errors },
  } = useForm<ProfessionalInfoT>({
    resolver: yupResolver(profileSchema),
    defaultValues: formDefaultValues,
  });

  return {
    register,
    watch,
    getValues,
    setValue,
    errors,
    handleSubmit,
    clearErrors,
    setError,
    trigger,
    reset,
  };
};
