import axiosBlobInstance from './axiosBlobInstance';
import axiosInstance from './axiosInstance';
import { DocumentActionDto, DocumentUpdateMetadataDto } from '@docbay/schemas';

export const AppointmentDocumentsApi = {
  postDocuments: (data: FormData) =>
    axiosInstance.post('/appointment-documents', data),
  postDocumentsToAppointment: (params: {
    appointmentId: string;
    data: FormData;
  }) =>
    axiosInstance.post(
      `/appointment-documents/${params.appointmentId}/upload`,
      params.data,
    ),
  downloadDocuments: (params: { bucketType: string; key: string }) =>
    axiosInstance.get(
      `/appointment-documents?bucketType=${params.bucketType}&key=${params.key}`,
    ),
  downloadDocument: (params: DocumentActionDto) => {
    const { relatedEntityId, bucketType, key } = params;
    return axiosBlobInstance.get('/appointment-documents/download', {
      params: {
        ...(relatedEntityId ? { relatedEntityId } : {}),
        bucketType,
        key,
      },
    });
  },
  deleteDocuments: (params: {
    relatedEntityId?: string;
    bucketType: string;
    key: string;
  }) =>
    axiosInstance.delete(
      `/appointment-documents?relatedEntityId=${
        params.relatedEntityId ? params.relatedEntityId : ''
      }&bucketType=${params.bucketType}&key=${params.key}`,
    ),
  editDocuments: (params: DocumentUpdateMetadataDto) =>
    axiosInstance.patch('/appointment-documents', params),
  downloadInvoice: (documentId: string) =>
    axiosBlobInstance.get(
      `/appointment-documents/download-invoice/${documentId}`,
    ),
};
