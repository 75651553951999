import { socialMediaValidation } from 'common/helpers/socialMediaValidation';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

export const usePersonalInfoSchema = () => {
  const { t } = useTranslation();
  const requiredError = t('errors.required');
  const urlFiledError = t('errors.must_be_url');
  const urlHttpsError = t('errors.https_error');
  const lengthMedicalNumberError = t('errors.at_least_2_characters');

  return yup.object({
    firstName: yup.string().required(requiredError),
    lastName: yup.string().required(requiredError),
    email: yup.string().email().required(requiredError),
    medicalNumber: yup
      .string()
      .required(requiredError)
      .min(2, lengthMedicalNumberError),
    description: yup.string().required(requiredError!),
    // Will be use in future
    // professionalActivityStartDate: yup.string().required(requiredError),
    spokenLanguageIds: yup
      .array()
      .of(yup.string())
      .min(1, requiredError!)
      .required(requiredError),
    dateOfBirth: yup.date().required(requiredError),
    socialMedia: yup.array().of(
      yup.object().shape({
        url: yup
          .string()
          .test('matchSocialMedia', urlFiledError, function (value, context) {
            if (!value) return true;

            if (!value.includes('https://')) {
              return context.createError({ message: urlHttpsError });
            }
            const isValid = socialMediaValidation(
              String(value),
              context.parent.name,
            );
            return isValid;
          }),
      }),
    ),
  });
};
