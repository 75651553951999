import React, { FC } from 'react';
import { PackageInfoStyled, StyledTr } from './styles';
import Counter from '../Counter';
import AvatarIcon from 'applicaiton/assets/avatar.svg';

interface PackageProps {
  quantity: number;
  handleSetQuantity: (value: number) => void;
  price: number;
  id: string;
  title: string;
  description: string;
  counterProps?: {
    inputDisabled?: boolean;
    increaseDisabled?: boolean;
    decreaseDisabled?: boolean;
  };
}

const Package: FC<PackageProps> = ({
  quantity,
  handleSetQuantity,
  price,
  id,
  title,
  description,
  counterProps,
}) => {
  return (
    <StyledTr>
      <td>
        <PackageInfoStyled>
          <img src={AvatarIcon} alt={id}></img>
          <div>
            <h2>{title}</h2>
            <p>{description}</p>
          </div>
        </PackageInfoStyled>
      </td>
      <td>
        <span>€{price}</span>
      </td>
      <td>
        <Counter
          value={quantity}
          handleSetQuantity={handleSetQuantity}
          id={id}
          {...counterProps}
        />
      </td>
      <td>
        <span style={{ width: 50 }}>€{price * quantity}</span>
      </td>
    </StyledTr>
  );
};

export default Package;
