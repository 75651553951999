import React, { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SubscriptionPackageDto } from '@docbay/schemas';
import {
  currentClinicOwnerRegistration,
  setClinicOwnerRegistrationSessionStorage,
} from 'applicaiton/sessionStorage/clinicRegistration';
import { getSubscriptionsAction } from 'applicaiton/store/reducers/ClinicRegistration/ActionCreators';
import { ReactComponent as QuantityIcon } from 'applicaiton/assets/quantity.svg';
import { setIsSubscriptionsFree } from 'applicaiton/store/reducers/ClinicRegistration/ClinicRegistrationSlice';
import { Checkbox, Loader, PrimaryButton } from 'common/components';
import { useAppDispatch, useAppSelector } from 'common/hooks/redux';
import Package from '../Package';
import ButtonGroup from '../ButtonGroup';
import {
  ColumnsStyled,
  PrivateDoctorToggleStyled,
  SectionStyled,
} from './styles';
import { usePrivateDoctorRegistration } from 'features/feature-clinic-registration/hooks/usePrivateDoctorRegistration';

interface PackageListProps {
  setStep: (value: number) => void;
}

const PackageList: FC<PackageListProps> = ({ setStep }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { isLoading, subscriptions } = useAppSelector(
    (state) => state.clinicRegistrationSlice,
  );
  const { subscriptionPackages } = currentClinicOwnerRegistration();
  const [packages, setPackages] = useState<{
    [key: string]: SubscriptionPackageDto[];
  }>(subscriptionPackages || {});
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { isPrivateDoctor, setPrivateDoctor } = usePrivateDoctorRegistration();

  useEffect(() => {
    dispatch(getSubscriptionsAction());
  }, [dispatch]);

  const handleSetPackage = (
    key: string,
    value: number,
    selectedPackage: SubscriptionPackageDto,
  ) => {
    if (value < 0) return;
    const createArrayOfPackages = Array.from(Array(value).keys()).map(
      () => selectedPackage,
    );
    const shouldPay =
      createArrayOfPackages.some(
        (item) => Number(item.metadata.price.unitAmountDecimal) > 0,
      ) || !createArrayOfPackages.length;

    dispatch(setIsSubscriptionsFree(!shouldPay));
    setClinicOwnerRegistrationSessionStorage('subscriptionPackages', {
      ...(subscriptionPackages || {}),
      [key]: createArrayOfPackages,
    });
    setPackages((prev) => ({ ...prev, [key]: createArrayOfPackages }));
  };

  const summary = useMemo(() => {
    const arrayOfSum = Object.values(packages).map((item) => {
      return item.reduce(
        (accumulator, currentValue) =>
          accumulator +
          Number(currentValue.metadata.price.unitAmountDecimal) / 100,
        0,
      );
    });
    const totalSum = arrayOfSum.reduce(
      (accumulator, currentValue) => accumulator + currentValue,
      0,
    );
    return totalSum;
  }, [packages]);

  const packagesCount = Object.values(packages).flat(1).length;

  const disableButton = !packagesCount;

  useEffect(() => {
    if (!isPrivateDoctor) {
      return;
    }
    if (packagesCount > 1) {
      setClinicOwnerRegistrationSessionStorage('subscriptionPackages', {});
      setPackages({});
    }
    if (subscriptions.length === 1) {
      handleSetPackage('quantity1', 1, subscriptions[0]);
    }
  }, [isPrivateDoctor, subscriptions]);

  const counterProps = useMemo(() => {
    if (!isPrivateDoctor) {
      return {};
    }
    const decreaseDisabled = subscriptions.length <= 1;
    const increaseDisabled = packagesCount > 0;
    return {
      increaseDisabled,
      decreaseDisabled,
      inputDisabled: true,
    };
  }, [isPrivateDoctor, packagesCount, subscriptions.length]);

  return (
    <SectionStyled>
      {isLoading && <Loader />}
      <div>
        <PrivateDoctorToggleStyled>
          <Checkbox
            id={'remember'}
            checked={isPrivateDoctor}
            onChange={setPrivateDoctor}
            label={t('clinicRegistration.private_doctor') || ''}
          />
        </PrivateDoctorToggleStyled>
        <table>
          <thead>
            <ColumnsStyled>
              <th>
                <span>{t('clinicRegistration.name')}</span>
              </th>
              <th>
                <span>{t('clinicRegistration.price_per1')}</span>
              </th>
              <th>
                <QuantityIcon />
                <span> {t('clinicRegistration.quantity')}</span>
              </th>
              <th>
                <span>{t('clinicRegistration.sum')}</span>
              </th>
            </ColumnsStyled>
          </thead>
          <tbody>
            {subscriptions.map((item, index) => (
              <Package
                key={item.metadata.productId}
                id={item.metadata.productId}
                quantity={packages[`quantity${index + 1}`]?.length || 0}
                handleSetQuantity={(value) =>
                  handleSetPackage(`quantity${index + 1}`, value, item)
                }
                title={item.name}
                description={item.description}
                price={Number(item.amount) / 100}
                counterProps={counterProps}
              />
            ))}
          </tbody>
        </table>
        {subscriptions.length ? (
          <p>
            {t('clinicRegistration.summary')}: €{summary}
          </p>
        ) : (
          ''
        )}
      </div>
      <ButtonGroup>
        <PrimaryButton onClick={() => setStep(1)} disabled={disableButton}>
          {t('clinicRegistration.continue')}
        </PrimaryButton>
      </ButtonGroup>
    </SectionStyled>
  );
};

export default PackageList;
