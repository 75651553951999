import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { ReactComponent as CloseIcon } from 'applicaiton/assets/close.svg';
import { ReactComponent as AddIcon } from 'applicaiton/assets/add.svg';
import Switch from 'common/components/Switch';
import TimePicker from 'common/components/TimePicker';
import { TimeWrapper, Wrapper, DividerStyled } from './styles';
import { TimeRangeProps } from './modules';
import { TimeSlots } from 'common/types/clinics';

//this value using to set one day for all slots
const defaultDate = '2000-01-01T';

const TimeRange: FC<TimeRangeProps> = ({
  day,
  slots,
  isEnabled,
  handleEditSlot,
  handleAddSlot,
  handleRemoveSlot,
  handleEnableSlot,
  hasError,
}) => {
  const { t } = useTranslation();

  const getMinStartTime = (startTime: string, index: number) => {
    if (!slots.length || index === 0) return null;

    return dayjs(
      !startTime
        ? `${dayjs().format('YYYY-MM-DD')}T` + slots[index - 1].endTime
        : defaultDate + slots[index - 1].endTime,
    );
  };

  const getMinEndTime = (startTime: string) => {
    const incrementedTime = dayjs(startTime).minute() + 5;
    return dayjs(startTime).set('minutes', incrementedTime);
  };

  const isInvalidValue = (currentDay: string, index: number, time: string) => {
    if (day !== currentDay || !time) return false;
    return slots.some((slot, i) => {
      if (i !== index) {
        return (slot.startTime || '') < time && (slot.endTime || '') > time;
      }
      return false;
    });
  };

  const handleChange = (
    value: string | null,
    index: number,
    key: keyof TimeSlots,
  ) => {
    handleEditSlot(day, index, {
      [key]: value ? dayjs(value).format('HH:mm') : '',
    });
  };

  return (
    <Wrapper>
      <h2>{t(day.toLowerCase())}</h2>
      <Switch
        checked={isEnabled}
        onChange={(value) => handleEnableSlot(day, value)}
      />
      <div>
        {slots.map((item, index) => (
          <TimeWrapper key={index}>
            <TimePicker
              defaultValue={dayjs(defaultDate + item.startTime)}
              value={
                item.startTime ? dayjs(defaultDate + item.startTime) : null
              }
              onChange={(value) => handleChange(value, index, 'startTime')}
              minTime={getMinStartTime(item.startTime, index)}
              maxTime={dayjs(defaultDate).hour(23).minute(55)}
              disabled={!isEnabled}
              isError={
                isInvalidValue(day, index, item.startTime!) ||
                (!item.startTime && !!hasError)
              }
            />
            <DividerStyled />
            <TimePicker
              defaultValue={dayjs(defaultDate + item.endTime)}
              value={item.endTime ? dayjs(defaultDate + item.endTime) : null}
              minTime={getMinEndTime(defaultDate + item.startTime)}
              maxTime={dayjs(defaultDate).hour(23).minute(59)}
              onChange={(value) => handleChange(value, index, 'endTime')}
              disabled={!item.startTime || !isEnabled}
              isError={
                isInvalidValue(day, index, item.endTime!) ||
                (!item.endTime && !!hasError)
              }
            />
            {index ? (
              <button
                onClick={() => handleRemoveSlot(day, index)}
                disabled={!isEnabled}
              >
                <CloseIcon />
              </button>
            ) : (
              ''
            )}
          </TimeWrapper>
        ))}
        <button
          disabled={!isEnabled}
          type="button"
          onClick={() => handleAddSlot(day)}
        >
          <AddIcon />
          <h2>{t('add_another')}</h2>
        </button>
      </div>
    </Wrapper>
  );
};

export default TimeRange;
