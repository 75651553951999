import styled from 'styled-components';

export const StyledTr = styled.tr`
  align-items: center;
  background-color: transparent;
  border: 1px solid;
  td {
    border-color: ${({ theme }) => theme.color.grey300} !important;
  }
  border-radius: 8px;
  cursor: pointer;

  td {
    text-align: center;
    vertical-align: middle;
    &:nth-child(3) {
      padding-left: 20px;
      padding-right: 40px;
    }
  }

  &:hover {
    background-color: ${({ theme }) => theme.color.blue50};
    td {
      border-color: ${({ theme }) => theme.color.blue300} !important;
    }
  }
`;

export const RightSection = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 327px;
  padding-left: 27px;

  span {
    width: 50px;
    text-align: right;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: ${({ theme }) => theme.color.grey800}
    font-weight: 500;

    &:last-child {
      font-weight: 600;
    }
  }
`;

export const PackageInfoStyled = styled.div`
  display: grid;
  width: fit-content;
  grid-template-columns: auto auto auto;
  gap: 16px;
  font-weight: 500;
  font-size: 16px;
  align-items: center;
  justify-content: space-between;

  label {
    margin-right: 6px;
  }

  img {
    width: 82px;
    height: 82px;
    border-radius: 50%;
  }
  h2 {
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
    color: ${({ theme }) => theme.color.grey800};
  }
  p {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: ${({ theme }) => theme.color.grey800};
    margin-top: 4px;
  }
`;
