import styled from 'styled-components';
import { StyledProps } from './modules';

export const StyledTr = styled.tr<StyledProps>`
  background: ${({ theme }) => theme.color.white};

  > td {
    vertical-align: middle;
    display: table-cell;
    text-align: center;
    padding-bottom: 12px;
    height: 46px;

    > div {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 12px;
      height: 100%;
      background: ${({ theme, isColored }) =>
        isColored ? theme.color.grey50 : theme.color.white};
    }

    &:first-child {
      width: inherit;
      text-align: start;

      > div {
        display: flex;
        align-items: center;
        justify-content: start;
        padding: 12px;
        height: 100%;
        background: ${({ theme, isColored }) =>
          isColored ? theme.color.grey50 : theme.color.white};
      }
    }

    p {
      color: ${({ theme }) => theme.color.grey900};
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 22.4px;
    }
  }
`;
