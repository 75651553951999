import React, { FC } from 'react';
import { StyledDiv } from './styles';
import { ReactComponent as LeftIcon } from 'applicaiton/assets/left.svg';
import { ReactComponent as RightIcon } from 'applicaiton/assets/right.svg';

interface CounterProps {
  value: number;
  handleSetQuantity: (value: number) => void;
  id: string;
  inputDisabled?: boolean;
  increaseDisabled?: boolean;
  decreaseDisabled?: boolean;
}

const Counter: FC<CounterProps> = ({
  value,
  id,
  handleSetQuantity,
  increaseDisabled,
  decreaseDisabled,
  inputDisabled,
}) => {
  return (
    <StyledDiv active={false}>
      <button
        onClick={() => handleSetQuantity(value - 1)}
        disabled={!value || decreaseDisabled}
      >
        <LeftIcon />
      </button>
      <input
        disabled={inputDisabled}
        id={id}
        type="number"
        value={value}
        onChange={(e) => handleSetQuantity(Number(e.target.value))}
      />
      <button
        onClick={() => handleSetQuantity(value + 1)}
        disabled={increaseDisabled}
      >
        <RightIcon />
      </button>
    </StyledDiv>
  );
};

export default Counter;
